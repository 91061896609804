<template>
  <div class="col-6 text-left back_btn font5" @click="$emit('click')">
    <span class="material-icons align-middle">
      keyboard_arrow_left
    </span> back
  </div>
</template>
<script>

// import Datepicker from 'vuejs-datepicker'
export default {
  name: 'BackButton'
}
</script>
